@import url("https://aicubix.com/css/aicubix-custom.css");
::placeholder {
  text-transform: capitalize;
}
bodyhtml h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: "Plus Jakarta Sans", sans-serif;
}
.position-relative {
  position: relative;
}
.flow-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.flow-sidebar {
  width: 260px;
  background-color: var(--white-color);
  padding: 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.flow-sidebar-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-height: calc(100vh - 75px);
  overflow: auto;
  padding-right: 5px;
}
.flow-sidebar-title {
  flex: 1 0 100%;
  font-size: 18px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  color: var(--purple-color);
  font-weight: 600;
}
.flow-sidebar-box .flow-sidebar-btn {
  flex: 0 0 calc(50% - 7.5px);
  flex-direction: column;
  text-align: center;
  margin: 0;
}
.flow-sidebar-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-block: 6px;
  border: 1px solid var(--border-color);
  padding: 12px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: var(--black555-color);
}
.flow-sidebar-btn > i {
  font-size: 16px;
  color: var(--purple-color);
}
.flow-sidebar-btn.comn-btn {
  color: var(--white-color);
}
.flow-sidebar-btn.comn-btn-border {
  color: var(--black555-color);
}
.flow-sidebar-btn.comn-btn-border:hover {
  color: var(--white-color);
}

.flow-sidebar-btn:last-child {
  margin-bottom: 0;
}
.flow-sidebar-btn.flow-temp {
  flex: 0 0 auto;
  width: calc(50% - 7.5px);
}

.flow-main-body {
  background-color: #f1f1f1;
  /* background-color: purple; */
  width: calc(100% - 240px);
  position: relative;
}

.flow-comn-box {
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0px 0px 25px #00000014;
  border: 2px solid transparent;
  transition: var(--transition);
  padding: 10px;
  position: relative;
  margin: 0;
}

.flow-comn-box:hover {
  border-color: var(--purple-color);
}
.flow-comn-box.flow-selected {
  border-color: var(--darkblue-color);
}
.flow-title-box {
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgb(0 152 134 / 10%);
  padding: 7px 12px 7px 22px;
  border-radius: 5px;
  border-right: 5px solid var(--purple-color);
  position: relative;
  margin-bottom: 0px;
}

.flow-title-box > aside {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: var(--purple-color);
}

.flow-textarea-row > div .flow-trigger,
.flow-trigger {
  position: absolute;
  right: 10px;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2.5px solid var(--purple-color);
  border-radius: 50%;
}
.flow-trigger-fill {
  right: auto;
  left: 5px;
  background-color: var(--purple-color);
}
.counter-class {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: right;
  font-size: 10px;
}
.textfield-padding-small textarea {
  padding-bottom: 20px;
}
.textfield-padding textarea {
  padding-bottom: 25px;
}
.no-drag {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.flow-inner-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: rgb(0 76 67 / 10%);
  padding: 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: var(--transition);
  margin-top: 8px;
}

.flow-inner-row:hover {
  border-color: var(--purple-color);
}

.flow-inner-box {
  position: relative;
  padding: 8px 16px;
  background-color: var(--white-color);
  border-radius: 4px;
}

.flow-comn-close-btn {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 8px;
  line-height: 1;
  color: #e72234;
  z-index: 1;
}

.edgebutton {
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background: #e5f5f3;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 0.7;
  color: var(--purple-color);
  border-color: var(--purple-color);
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}
.flow-file-input {
  position: relative;
  z-index: 1;
}
.flow-file-input input[type="file"] {
  padding-left: 26px;
}
.flow-file-input input[type="file"]::-webkit-file-upload-button {
  display: none;
}
.flow-file-input > i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 152 134 / 10%);
  border: 1px solid rgb(0 152 134);
  color: rgb(0 152 134);
  border-radius: 3px;
  font-size: 12px;
  z-index: -1;
}
.flow-image-preview-box {
  height: calc(100% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 5px;
  overflow: hidden;
}
.flow-image-preview-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.flow-list-textarea fieldset {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 0px;
}
.flow-list-textarea:last-child fieldset {
  border-bottom: none;
}
.coming-soon-text {
  display: inline-block;
  padding: 2px 10px;
  background-color: rgb(0 76 67 / 8%);
  border-radius: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 9px;
  /* margin-left: auto; */
  color: var(--darkblue-color);
}
.warning-row {
  bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  left: 5px;
  position: absolute;
  width: calc(100% - 225px);
  z-index: 1;
}
.warning-text {
  flex: 1 0 250px;
  font-size: 14px;
  line-height: 140%;
  color: var(--black333-color);
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
  border-radius: 8px;
  text-align: center;
}
.flow-whatsapp-preview-container {
  margin: 10px;
}
.flow-whatsapp-preview-box {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 0px 5px 5px;
  max-width: 85%;
  position: relative;
}
.flow-whatsapp-preview-box::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  border-top: 6px solid #ffffff;
  border-left: 6px solid transparent;
}
.flow-whatsapp-preview-box > img {
  width: 100%;
  margin-bottom: 10px;
}
.flow-whatsapp-preview-btn-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  max-width: 85%;
}
.flow-whatsapp-preview-btn-row > * {
  flex: 1 0 80px;
  background-color: #ffffff;
  color: #0041d0;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
}
.flow-full-btn > * {
  flex-basis: 100%;
}
.flow-link-text {
  display: block;
  width: 100%;
  text-align: center;
  padding: 6px 0px;
  margin-bottom: -10px;
  border-top: 1px solid #eee;
  margin-top: 10px;
  text-transform: capitalize;
}
.preview-eye-button {
  cursor: pointer;
  font-size: 12px;
  /* margin-left: auto; */
  color: var(--purple-color);
}
.preview-clone-button {
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  color: var(--purple-color);
}
.file-upload-name-label {
  padding-left: 25px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.file-upload-name-input {
  display: none !important;
}
.flow-image-header-row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background: #efefef;
  gap: 10px;
  /* border: 1px solid #efefef; */
}
.flow-image-header-row > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  flex-shrink: 0;
}
.flow-image-header-row > div {
  flex: 1 1 100%;
}
.flow-textarea-row {
  gap: 0;
  border-radius: 4px;
}
.flow-textarea-row > aside {
  position: relative;
}
.flow-textarea-row > aside > div {
  width: 100%;
}
.flow-textarea-row > div > div,
.flow-textarea-row > aside > div > div {
  border-radius: 0px;
  border: none;
  padding: 10px 8px;
  background-color: var(--white-color);
}
.flow-textarea-row fieldset {
  border: none;
  border-bottom: 1px solid #dddddd;
}
.text-btn-add-row fieldset {
  border: none;
}
.flow-comn-btn,
button.flow-comn-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  border: none;
  background-color: var(--white-color);
  color: var(--black333-color);
  padding: 10px;
  margin-top: 8px;
  box-shadow: none;
}
.flow-comn-btn:hover,
button.flow-comn-btn:hover {
  background-color: var(--purple-color);
  color: var(--white-color);
  box-shadow: none;
}
.text-btn-add-row {
  margin-top: 8px;
}
.text-btn-add-row > div > div {
  border-radius: 0px;
}
.error-message-row {
  position: absolute;
  bottom: 5px;
  z-index: 1;
  left: 5px;
}
.flow-file-input .error-message-row {
  bottom: 0px;
  left: auto;
  right: -12px;
}
.required-field label {
  font-size: 14px;
}
.header-btn-row {
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.flow-comn-box:hover .header-btn-row {
  opacity: 1;
  visibility: visible;
}
.MuiModal-root.flow-modal,
.flow-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flow-modal .modal-body {
  max-width: 500px !important;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
}
.modal-btn-row {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}
.modal-btn-row .comn-btn:disabled {
  pointer-events: none;
  opacity: 0.6;
}
.minmax-row {
  display: flex;
  gap: 5px;
  align-items: center;
}
.minmax-row > input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 3px 10px;
  border-radius: 5px;
}
.flow-stop-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
}
.flow-stop-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  flex-shrink: 0;
  color: var(--purple-color);
  font-size: 26px;
}
.dropdown-zindex > div + div {
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
.comn-or-text {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-block: 5px;
}
.comn-or-text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  z-index: -1;
}
.comn-or-text > span {
  font-size: 14px;
  color: #333333;
  padding: 0px 10px;
  text-align: center;
  background-color: #ffffff;
}
.flow-file-input > div::before {
  display: none;
}
.lost-btn-row {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.lost-btn-row > * {
  flex-basis: 80px;
}
.sidebar-bottom-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: auto;
}
.sidebar-bottom-btn > * {
  flex: 1 0 100px;
  margin: 0;
}
.content-dropdown ul {
  padding: 0;
}
.content-dropdown ul li {
  padding-block: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.myuihub-loader3 {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 10px #56886e double;
  border-left-style: solid;
  border-radius: 100%;
  animation: myuihubLoader3 0.6s infinite linear;
}

@keyframes myuihubLoader3 {
  to {
    transform: rotate(360deg);
  }
}

.flow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.flow-header .flow-comn-close-btn {
  position: initial;
  font-size: 10px;
}
.data-card {
  padding: 10px;
  background: #fff;
  margin-top: 10px;
  border-radius: 5px;
}
.data-card-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}
.select-height #demo-simple-select2 {
  height: 50px;
}
