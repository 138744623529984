body {
  font-size: 14px;
}
img {
  max-width: 100%;
  height: auto;
}

.loaderdiv-outer {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.loaderbox {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20vh;
  height: 20vh;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-edit {
  background-color: #fff3cd !important;
  color: #856404;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}
.action-view {
  background-color: #2639ff45 !important;
  color: #3a50e9;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}
.action-delete {
  background-color: #f8d7da !important;
  color: #721c24;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}

.react-action-class button {
  height: auto !important;
  width: auto !important;
}

.react-action-class button.table-action {
  border-radius: 5px;
  height: 24px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  text-align: center;
  width: 24px !important;
}

.chargeButton {
  background: rgb(44, 129, 255);
  color: black;
  border: none;
}

.react-action-class button {
  background: transparent;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9375rem;
  height: 1.875rem;
  margin-right: 5px;
  width: 1.875rem;
}

.evHeader {
  background-color: "rgb(44 92 255)";
}

/* .thead-color {
  border-bottom: 3px solid black;
  padding: 10px;
  text-align: left;
} */
.table td,
.table th {
  padding: 5px 0.75rem;
  border-top: 1px solid #dee2e6;
  height: 55px;
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 13px;
}
.table tr:nth-child(even) {
  background: #f7f6f6;
}
.table thead th {
  vertical-align: middle;
  font-weight: 600;
  border-top: 0;
}
.btn-success {
  font-size: 14px;
}
.table-white-box {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 10px;
}
.table-white-box .table {
  border: 0;
  margin-bottom: 0;
}
.form-control {
  height: calc(1.71em + 0.75rem + 2px);
}
.btn-secondary {
  background-color: #333;
  border-color: #333;
}
.pos-relative.sidebar-whole-list {
  background: #f1f9f1;
  border-radius: 5px;
  border: 1px solid #d8ebd8;
}
.sidebar-wrapper .sidebar-whole-list .child-list {
  margin-top: 3px !important;
  padding-top: 1px;
}

.previewImage {
  width: 60px;
  height: 60px;
  border: 1px solid rgb(221, 221, 221);
  padding: 3px;
  margin-top: 10px;
}

.imageView {
  height: 60px;
  width: 80px;
}
.btnsize {
  width: 77px;
}
.confirmBtn {
  width: 77px;
  height: 40px;
  padding: 8px;
}
.RedClass {
  color: Red;
  font-weight: bold;
}

.EmailShow {
  padding: 5px 0.75rem;
  height: 55px;
  vertical-align: middle;
  font-size: 13px;
  text-transform: lowercase !important;
}
.add-button-commmon{
  z-index: 999 !important;
}

.action-view {
  background-color: #d7e9f1 !important;
  color: #1066e9;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #cccccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type="time"]:focus {
  border: 3px solid #555;
}

.thead-success {
  color: white;
  background-color: #30c464;
}

.viewCharger {
  height: 45px !important;
}

.time-placeholder {
  position: absolute;
  right: 29px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(78%);
  color: #aaa;
}
.dashboard-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #25547b14;
  border-radius: 10px;
  position: relative;
  text-align: left;
  border: 1px solid #ddd;
}
.centered-datepicker {
  text-align: center;
}
.dashboard-datepicker {
  margin-bottom: 12px !important;
  border-radius: 12px !important;
  border-color: #00d60b !important;
  padding: 5px !important;
}
.card-title {
  margin-bottom: 0.75rem;
  display: flex;
  gap: 10px;
  align-items: center;
}

.table-responsive {
  overflow-x: unset !important;
}




/* CHATBOT CSS START */

div.customHandle {
  width: 100px;
  height: 100%;
  opacity: 9;
  background-color: blue;
  /* position: absolute;
  top: 0;
  left: 0; */
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}
.add-content-btn-box{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;}
.add-content-btn-box button {
  width: calc(100% - 10px);
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  border: 1px solid #E9EDEF;
  padding: 8px;
  color: #4A4A4A;
  display: inline-flex;
  align-items: center;
  gap:8px;
  background-color: #F1F1F1;
}

.loader-child-class{
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* ---------------------------------Loader css start here --------------------------------------*/

.myuihub-loader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
}

.myuihub-loader span {
  display: block;
  width: 10px;
  height: 100%;
  flex-shrink: 0;
  --rotation: 68;
  background-color: #193D84;
  opacity: 0;
  transform-origin: bottom right;
  animation: myuihubLoader 1s infinite ease;
}

.myuihub-loader span:nth-child(1) {
  animation-delay: 0.2s;
}

.myuihub-loader span:nth-child(2) {
  animation-delay: 0.4s;
}

.myuihub-loader span:nth-child(3) {
  animation-delay: 0.6s;
}

.myuihub-loader span:nth-child(4) {
  animation-delay: 0.8s;
}

.myuihub-loader span:nth-child(5) {
  animation-delay: 1s;
  --rotation: 90;
}

@keyframes myuihubLoader {
  0% {
      opacity: 0;
  }
  20% {
      opacity: 1;
      transform: rotate(0deg);
  }
  60% {
      transform: rotate(calc(var(--rotation) * 1deg));
  }
  90%,
  100% {
      opacity: 0;
      transform: rotate(calc(var(--rotation) * 1deg));
  }
}
/* -----------------------------------------Loader 2 css -------------------------------------- */
.myuihub-loader2 {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  box-shadow: 25px 0px 0 0 rgba(0, 0, 0, 0.2), 20.22542px 14.69463px 0 0 rgba(0, 0, 0, 0.4), 7.72542px 23.77641px 0 0 rgba(0, 0, 0, 0.6), -7.72542px 23.77641px 0 0 rgba(0, 0, 0, 0.8), -20.22542px 14.69463px 0 0 rgba(0, 0, 0);
  animation: myuihubLoader2 1s infinite linear;
}

@keyframes myuihubLoader2 {
  to {
      transform: rotate(360deg);
  }
}