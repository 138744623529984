/* otp.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for the OTP input */
.otp-input-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otp-input {
  width: 50px !important;
  height: 40px;
  font-size: 16px;
  margin: 0 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Additional styles as needed */
